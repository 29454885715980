#menu{
    width: 480px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    @apply bg-blueLight fixed h-full right-0 top-0 z-10 overflow-x-hidden overflow-y-scroll
}

#menu::-webkit-scrollbar {
    display: none;
}

#menu a:visited,
#menu a:hover{
    color: white;
}

@media (max-width: 500px) { 
    #menu{
        width: 100%;
        @apply w-screen h-screen overflow-scroll
    }
  }

section.Header .navigation .meta .subnav{
    position: absolute;
    font-size: 16px;
}

section.Header .navigation .subnav.user{
    min-width: 150px;
}

section.Header .isFolderOpener:hover  .subnav {
  visibility: visible;
}

section.Header .isFolderOpener .subnav{
    visibility: hidden;
    padding-top: 10px;
    position: absolute;
    font-size: 16px;
}

section.Header .isFolderOpener .subnav > div{
    @apply bg-blueLight border-0 border-t border-white h-8 flex items-center
}

section.Header .isFolderOpener .subnav > div:nth-child(1){
    @apply border-0
}