.stickyContainer{
    box-shadow: 0px 15px 13px -9px #EFF2F6 ;
}

svg:not(:root) {
    overflow: visible!important;
}

.IoT .doorAnimation_south_door,
.IoT .doorAnimation_north_south{
    position:relative;
    margin-top:-40px;
    z-index: 1;
}

@media (max-width: 993px) { 
    .IoT .doorAnimation_south_door,
    .IoT .doorAnimation_north_south{
        margin-top:20px;
    }
  }

/* one door */
.IoT .doorAnimation_south_door .doorRight{
    transform: translate(92px, 6px) scale(1.03);
    transition: 1.0s ease;
}

.IoT .doorAnimation_south_door.door_south_open .doorRight{
    transform: translate(137px, 2px) scale(0.99);
    transition: 1.0s ease;
}

.IoT .doorAnimation_south_door .doorLeft{
    transform: translate( 44px, 11px) scale(0.96);
    transition: 1.0s ease;
}

.IoT .doorAnimation_south_door.door_south_open .doorLeft {
    transform: translate( 3px, 15px) scale(1.0);
    transition: 1.0s ease;
}

/* doors north-south */
.IoT .doorAnimation_north_south .doorRight{
    transform: translate(92px, 6px) scale(1.03);
    transition: 1.0s ease;
}


.IoT .doorAnimation_north_south.door_north_open .doorNorth .doorRight,
.IoT .doorAnimation_north_south.door_south_open .doorSouth .doorRight{
    transform: translate(137px, 2px) scale(0.99);
    transition: 1.0s ease;
}

.IoT .doorAnimation_north_south .doorLeft{
    transform: translate( 44px, 11px) scale(0.96);
    transition: 1.0s ease;
}

.IoT .doorAnimation_north_south.door_north_open .doorNorth .doorLeft,
.IoT .doorAnimation_north_south.door_south_open .doorSouth .doorLeft {
    transform: translate( 3px, 15px) scale(1.0);
    transition: 1.0s ease;
}
