
.tile{
    height: 365px;
    border-bottom: 25px solid;
    @apply border-greyLighter
}

@media (max-width: 769px) { 

    .tile.TileInstallations{
        height: inherit;
        padding-bottom: 40px;
    }
    

}

.tile.small{
    height: 42px;
    @apply border-0
}

.tile img.icon{
    height: 25px;
    @apply pr-4
}

.tile.small img.icon{
    height: 21px;
    @apply pr-3
}

.tile.small img.arrow{
    width: 40px!Important;
}

.tile.small img.arrow.opener{
    width: 18px!Important;
}

.tile .arrow.link{
    width: 11px!Important;
}
.tile .fixHeightIfOneButtonIsAtTheBottom{
    height: 200px;
}