@tailwind base;
@tailwind components;
@tailwind utilities;


body{ 
  font-family: Lato;
  @apply text-blueDark m-0 bg-greyLighter
}

.UserLoggedOut .Footer a:visited,
.UserLoggedOut .Footer a:hover {
  @apply text-white
}

h1,h2,h3,h4,h5,h6{
  @apply text-blueDark
}

body.UserLoggedOut {
  background-image: url('./assets/images/bg_welcome.jpg');
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-attachment:scroll;
}

body.isDashboard{
  @apply bg-white
}

h1{ font-weight: 900; font-size: 36px; line-height: 45px; letter-spacing: 0.5px;}

h2,h3,h4,h5,h6,
.h2,.h3,.h4,.h5,.h6{ font-weight: 700; letter-spacing: 0.5px;}

.h2, h2{ font-size: 32px; line-height: 45px;}
.h3, h3{ font-size: 28px; line-height: 35px;}
.h4, h4{ font-size: 24px; line-height: 30px;}
.h5, h5{ font-size: 21px; line-height: 26px;}
.h6, h6{ font-size: 18px; line-height: 23px;}

.s1{ font-size: 16px; font-weight: bold; line-height: 27px; letter-spacing: 0px;}

@media (min-width: 769px) { 
  h1{font-size: 48px; line-height: 60px; letter-spacing: 1px;}
  h2{ font-size: 48px; line-height: 60px; letter-spacing: 1px;}
  h3{ font-size: 40px; line-height: 50px; letter-spacing: 1px;}
  h4{ font-size: 32px; line-height: 40px;}
  h5{ font-size: 26px; line-height: 32px;}
  h6{ font-size: 21px; line-height: 26px;}

  p{ font-size: 21px; line-height: 32px; letter-spacing: 0.5px;}
  .p2{ font-size: 18px; line-height: 27px; letter-spacing: 0.5px;}

  .s1{ font-size: 18px; letter-spacing: 1px;}
  .s2{ font-size: 16px; line-height: 24px; letter-spacing: 0.5px;}
  .s3{ font-size: 40px; line-height: 56px; letter-spacing: 1px;}
}

@media (min-width: 993px) { 
  h1{ font-size: 60px; line-height: 75px;}
}

.grid{
  @apply gap-x-4 gap-y-4 mb-4 s:gap-x-5 s:gap-y-5 s:mb-5 m:gap-x-6 m:gap-y-6 m:mb-6 l:gap-x-8 l:gap-y-8 l:mb-8 xl:gap-x-10 xl:gap-y-10 xl:mb-10
}

.grid.gridWithoutMB{
  @apply mb-0
}

div.Content > div,
section.Content > div{
  max-width: 1550px;
  @apply m-auto pl-4 pr-4 xxl:pl-0 xxl:pr-0
}

.isDashboard section.Content > div{
    max-width: 1180px;
}

.link{ cursor: pointer;}

.sbButton {
  font-size: 18px!important;
  min-width: 280px;
  @apply text-white bg-blueDark !important rounded  w-full
}

@media (max-width: 769px) { 
  .sbButton {
    min-width: inherit;
  }
}

.sbButton.isLoading {
  height: 54px!important;
  padding-bottom: 10px;
}

.sbButton.isNotLoading span{
  @apply  pt-3.5 pb-3.5 pl-6 pr-6 flex !important justify-between  w-full
}

.sbButton.isNotLoading span{
  @apply flex w-full p-2 !important
}

.sbButton.isNotLoading span img{ 
  width: 40px!important;
  margin-left: 30px;
  display: inline-block;
}
.ant-btn{ height: auto!important;border-radius: 5px;}

.ant-table-filter-trigger:hover{
  @apply text-white
}

thead[class*="ant-table-thead"] th.ant-table-column-has-sorters:hover,
thead[class*="ant-table-thead"] th.ant-table-column-sort,
thead[class*="ant-table-thead"] th{
  @apply bg-blueDark text-white
}

.subPageContentContainer{
  @apply bg-white pt-9 pb-9
}

.subPageContentContainer .innerElement{
  @apply pl-7 pr-7
}

.ant-table-pagination-right{
  @apply pr-7
}

.ant-table-tbody > tr > td {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 35px;
}

.ant-table-thead > tr > th {
  padding-left: 35px;
}

.Content,
.ant-tabs,
.ant-table{
  @apply text-blueDark
}

.ant-popover.headlinePopover{
  width: 30vw;
  @apply w-full m:w-8/12 l:w-4/12
}

.isInstallationDetailPage .ant-popover-inner,
.isDashboard .ant-popover-inner {
  background-color:#EFF2F6;
}


.isDashboard .headlinePopover .ant-popover-arrow-content,
.isInstallationDetailPage .headlinePopover .ant-popover-arrow-content{
  background-color: #EFF2F6;
}