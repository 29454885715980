section.Footer > div{
    max-width: 1500px;
    @apply m-auto pl-8 pr-8 pb-1
  }



.topElem > div:nth-child(2){
  line-height: 40px;
}

.UserLoggedOut{ 
  @apply text-white
}

.UserLoggedOut #root{
  @apply flex flex-col h-full
}

.bottomElem{
  border-top: 1px solid transparent;
  @apply border-blueDark
}

.UserLoggedOut .bottomElem{
  @apply border-white
}

.Footer svg{
  @apply fill-current text-blueDark
}

.UserLoggedOut .Footer svg{
  @apply fill-current text-white
}


@media (max-width: 769px) { 

  section.Footer{
    margin-top: 60px;
  }

  section.Footer > div{
    @apply pl-4 pr-4 pb-1
  }

  .Footer .topElem{
    @apply flex-col flex-col-reverse pl-4 pr-4
  }

  .Footer .bottomElem{
    @apply pl-4 pr-4
  }
  
  .Footer .bottomElem > div{
    justify-content: space-between;
    @apply flex justify-between
  }

  .Footer .bottomElem > div:nth-child(2) > a:nth-child(2){
    @apply pr-0
  }

  .Footer .topElem > div{
    @apply self-start
  }

  .Footer .topElem > div:nth-child(2){
    line-height: 40px;
    margin-bottom: 30px;
  }
}

