section.Header .wrapper{
    height: 105px;
    box-shadow: 0 4px 8px rgb(4 31 61 / 8%);
    width: auto;
    max-width: inherit;
    @apply m-0  mb-9 bg-white
}

section.Header .navigation{
    font-size: 20px;
}

section.Header .meta{
    font-size: 18px;
}

section.Header .logo svg {
    height: 56px;
}

