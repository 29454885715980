.documentOverlay{
  box-shadow: 0 4px 8px 0 rgba(1, 1, 1, 1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  @apply absolute  bg-white 
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.gallery .img {
  margin: 1%;
  border: 0px solid #000;
  box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
  width: 100%;
}

.gallery .img img{
  max-width: 300px;
  max-height: 300px;
}

@media (min-width: 600px) { 
  .gallery .img { 
    width: calc(96% / 2);
  }
}

@media (min-width: 769px) { 
  .gallery .img { 
    width: calc(94% / 3);
  }
}

.gallery .tooltip{
  background-color: white;
  padding: 10px;
}

.ant-btn > span > svg {
  margin-top: -6px;
}

.ant-radio-group .ant-radio-button-wrapper,
.categoryFilter button{
  @apply mr-3 mb-2
}

.showMousePointer:hover{
  cursor: pointer;
}