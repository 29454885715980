.ahButton {
    font-size: 18px;
    @apply text-white bg-blueLight rounded  w-full flex cursor-pointer
  }
  
.ahButton span{
    @apply p-2 pl-3 flex !important justify-between  w-full
}

.ahButton img{ 
    @apply mr-4 ml-7 inline-block w-10
}