
body.isInstallationDetailPage section.Header{
    @apply bg-white overflow-auto
  }

.isInstallationDetailPage  .subPageContentContainer{
  padding-bottom: 0px;
  padding-top: 5px;
  flex-direction: row;
}

.rowMap{
  display: inline-block;
  width: 100%;
  height: 200px;
  padding-bottom: 2.25rem;
}

.rowData{
  display: inline-block;
  width: 100%;
  padding-bottom: 2.25rem;
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.rowContract{
  display: inline-block;
  align-self: stretch;
  flex-grow: 1;
  flex: 1;
  align-items: stretch;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.rowContract .contract{
  display: none;
}

.rowContract .container > div,
.rowData > div{
  border-bottom: 1px solid ;
  padding: 10px;
  @apply border-blueDark
}

.rowData > div > div:nth-child(1){
  min-width: 150px;
}

.rowContract .container{
  height: calc(100% - 347px);
}

.rowContract .container > div{
  padding: 10px;
  @apply border-blueDark
}


.rowContract .container > div > div:nth-child(1){
  min-width: 150px;
}

.ant-tabs-nav{margin-bottom: 0px!important;}

.isInstallationDetailPage section.Content > div{
  @apply pl-0 pr-0 s:pl-4 s:pr-4
}

.ant-badge{
  color: inherit!important;
  font-size: inherit!important;
}


.mapMarker{
  margin-top:-28px;
  margin-left:-18px;
}

@media (min-width: 900px) { 
  .rowData{
    width: 375px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 40px;
  }

  .rowContract{
    padding-left: 0px;
    padding-right: 0px;
    display: inline-block;
  }
}

@media (min-width: 1400px) { 
  .rowMap{
    width: 450px;
    height: inherit;
    margin-right: 62px;
  }

  .rowData{
    width: 375px;
    margin-right: 85px;
    padding-bottom: 2.25rem;
  }

  .rowContract{
    align-self: stretch;
    flex-grow: 1;
    flex: 1;
    align-items: stretch;
    margin-bottom: 0px;
  }

  .rowContract .contract{
    display: inherit;
  }

}
