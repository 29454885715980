

.contractContainer .listItem{
  border-bottom: 1px solid ;
  padding: 10px;
  @apply border-blueDark
}

.contractContainer .listItem > div:nth-child(1){
  min-width: 150px;
}

.hint{
  background-color:rgba(136, 224, 254, 0.2);
  padding: 10px 20px;
}

.title{
  font-weight: bold;
}

.contractDownload{
  margin-top: 10px;
}

.contracDummyBig{
  border: 1px solid #D6D6D6;
  display: none;
}

.serachIcon{
  margin-bottom: 70px;
}