.loginForm{
    width: 560px;
    @apply pl-10 pr-10 pt-11 pb-11
}

.loginForm a{
    @apply text-blueLight
}

.ant-form-item-label{ padding: 0px!important;}

@media (max-width: 769px) { 
    .loginForm{
        width: 94%;
        margin: 0 3% 0 3%!important;
    }
}