.googleMapsInfoWindow{
  position: absolute;
  display: inline-flex;
  background-color: white;
  padding: 16px;
  border: 0px solid black;
  z-index: 100;
  white-space: nowrap;
  left: 40px;
  top:-8px;
  @apply bg-white text-blueDark
}